import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import loading from "../../../../../assets/images/loading.gif";
import { isLoadOpen } from "../../functions";
import { IStore } from "../../../../app/types";
import { connect } from "../../../redux";

function Loading(props) {    
    const data = props.isLoad ? props : useSelector((state) => state['features/base/loading']);

    const { load, option } = useMemo(() => data, [data]);
    return (
        <div className="load_cover center nonSelect" style={{
            background: `${option ? "rgba(0,0,0, 0.8)": "rgba(0,0,0, 0.5)"}`,
            display: `${load ? "block" : "none"}`,
            zIndex: 9999,
            left: "50%"
        }}>
             <div className="load center nonSelect">                
                <dl style={{
                    background: `url(${loading}) no-repeat`,
                    width: "65px",
                    height: "65px",
                }}></dl>
                <span className="text overText">{ option || 'LOADING' }</span>
            </div> 
        </div>
    )
}


export default connect()(Loading);