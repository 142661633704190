import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ClickButton } from "../../ui";
import { bytesToSize, getFileLink } from "../functions";
import { ROLE, getLocalMemberRole } from "../../members";
const ChatFileItem = React.memo(({ message, time, uuid }) => {
    const { t } = useTranslation();
    const file = useSelector((state) => getFileLink(state, uuid));
    const isChatLock = useSelector((state) => state["features/base/settings"].policy.file_option === 'allow' ? false : true);
    const local_role = useSelector((state) => getLocalMemberRole(state));
    const lockPermission = useMemo(() => {
        if (isChatLock && (local_role && ROLE.PARTICIPANT === local_role))
            return false;
        return true;
    }, [isChatLock, local_role]);
    const onClick = () => {
        if (!file)
            return;
        if (!lockPermission) {
            APP.UI.alertMessage("chat.noDownPermission");
            return;
        }
        const downloadLinkArr = file.link.split("/");
        fetch(`/fileshare/download/${downloadLinkArr[4]}/${downloadLinkArr[5]}`)
            .then(response => response.blob())
            .then(result => {
            var uri = URL.createObjectURL(result);
            var link = document.createElement("a");
            link.setAttribute("href", uri);
            link.download = message;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
            .catch(error => {
            APP.UI.alertMessage("chat.failDownload");
        });
    };
    if (!file) {
        return (React.createElement("div", { className: "message" },
            React.createElement("span", null,
                " ",
                time,
                " "),
            React.createElement("div", { className: "overText" }, t("chat.sending")),
            React.createElement(ClickButton, { icon: lockPermission ? "fileDownOff" : "fileDownOn", size: 36, onClick: onClick })));
    }
    return (React.createElement("div", { className: "message" },
        React.createElement("div", { className: "overText" },
            message,
            " "),
        React.createElement("span", null,
            " ",
            time,
            " "),
        React.createElement(ClickButton, { className: "download", icon: lockPermission ? "fileDownOn" : "fileDownOff", label: bytesToSize(file.size), size: 24, onClick: onClick })));
});
export default ChatFileItem;
