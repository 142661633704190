import { LIB_DID_DISPOSE, LIB_DID_INIT, LIB_INIT_ERROR, LIB_WILL_DISPOSE, LIB_WILL_INIT } from './actionTypes';
/**
 * Disposes (of) lib-jitsi-meet.
 *
 * @returns {Function}
 */
export function disposeLib() {
    return (dispatch) => {
        dispatch({ type: LIB_WILL_DISPOSE });
        // TODO Currently, lib-jitsi-meet doesn't have the functionality to
        // dispose itself.
        dispatch({ type: LIB_DID_DISPOSE });
    };
}
/**
 * Initializes lib-jitsi-meet (i.e. {@link invokes JitsiMeetJS.init()}) with the
 * current config(uration).
 *
 * @returns {Function}
 */
export function initLib() {
    return (dispatch, getState) => {
        dispatch({ type: LIB_WILL_INIT });
        try {
            JitsiMeetJS.init({
                createVADProcessor: null,
                p2p: false,
                enabled: true,
                enableTalkWhileMuted: true,
                enableUnifiedOnChrome: true,
                enableNoisyMicDetection: true,
                openBridgeChannel: 'websocket',
                disableSimulcast: false,
                sourceNameSignaling: true,
                sendMultipleVideoStreams: false,
                receiveMultipleVideoStreams: false,
                enableLayerSuspension: false,
                videoQuality: {
                    maxBitratesVideo: {
                        low: 100000,
                        standard: 500000,
                        high: 2000000
                    }
                }
            });
            JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.DEBUG);
            console.info(`lib-jitsi-meet version:${JitsiMeetJS.version}`);
            dispatch({ type: LIB_DID_INIT });
        }
        catch (error) {
            dispatch(libInitError(error));
        }
    };
}
/**
 * Notifies about a specific error raised by {@link JitsiMeetJS.init()}.
 *
 * @param {Error} error - The Error raised by JitsiMeetJS.init().
 * @returns {{
 *     type: LIB_INIT_ERROR,
 *     error: Error
 * }}
 */
export function libInitError(error) {
    return {
        type: LIB_INIT_ERROR,
        error
    };
}
