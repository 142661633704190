import { APP_WILL_MOUNT, APP_WILL_UNMOUNT } from '../app';
import JitsiMeetJS, { JitsiMediaDevicesEvents } from '../lib-jitsi-meet';
import { MEDIA_TYPE } from '../media';
import { MiddlewareRegistry } from "../redux";
import { SET_AUDIO_INPUT_DEVICE, SET_VIDEO_INPUT_DEVICE } from './actionTypes';
import { configureInitialDevices, devicePermissionsChanged } from './actions';

/**
 * A listener for device permissions changed reported from lib-jitsi-meet.
 */
let permissionsListener;

MiddlewareRegistry.register(store => next => action => {
    switch (action.type) {
        case APP_WILL_MOUNT:
            if (store.getState()['features/base/app'].app === true) return;
            // 장치에 대한 권한 확인 + 권한 업데이트 이벤트 추가 
            const _permissionsListener = permissions => {
                store.dispatch(devicePermissionsChanged(permissions));
                store.dispatch(configureInitialDevices());
            }
            
            const { mediaDevices } = JitsiMeetJS;

            permissionsListener = _permissionsListener;
            mediaDevices.addEventListener(JitsiMediaDevicesEvents.PERMISSIONS_CHANGED, permissionsListener);
            Promise.all([
                mediaDevices.isDevicePermissionGranted('audio'),
                mediaDevices.isDevicePermissionGranted('video')
            ]).then(results => {

                console.log(results)
                _permissionsListener({
                    audio: results[0],
                    video: results[1]
                });
            }).catch(() => {

            });
            break;
    
        case APP_WILL_UNMOUNT:
            // 장치 권한 이벤트 삭제 
            if (typeof permissionsListener === 'function') {
                JitsiMeetJS.mediaDevices.removeEventListener(
                    JitsiMediaDevicesEvents.PERMISSIONS_CHANGED, permissionsListener);
                permissionsListener = undefined;
            }
            break;

        case SET_AUDIO_INPUT_DEVICE:            
            APP.management.updateTrack({ micDeviceId: action.deviceId }, MEDIA_TYPE.AUDIO);
            // store.dispatch(replaceAudioTrackById(action.deviceId));
 
            break;

        case SET_VIDEO_INPUT_DEVICE:
            APP.management.updateTrack({ cameraDeviceId: action.deviceId }, MEDIA_TYPE.VIDEO);

            break;
    }
    
    return next(action);
});