import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DevicePermissionContainer } from "../../device-permission";
import { RaiseHandContainer } from "../../raise-hand";
import { ScreenSetting } from "../../screen-share";
import ChatLockFile from "../chat/ChatLockFile";
import { Dialog, hideDialog } from "../dialog";
import { LayoutCountContainer } from "../layout-count";
import { SettingNotificationContainer } from "../notification";
import { ClickButton, DivElement, OnOffSwitch, RadioButton, TextElement } from "../ui";
import { updateGridOption, updateRoomPolicy, updateSettings } from "./actions";
import { SettingStyled } from "./components";
import { CHAT_FILE_LOCK, COUNT_SET, DEVICE_SET, HANDLER_SET, LABEL_SET, NOTIFICATION_SET, SCREEN_LAYOUT_SET, SETTING_LIST } from "./constants";
function SettingContainerDialog({ permissions, isMobile = false }) {
    const layoutRef = useRef(null);
    // useTranslation을 사용하여 다국어 처리
    const { t } = useTranslation();
    // Redux의 state에 따라서 작동
    const dispatch = useDispatch();
    const { grid_count, policy, enableIconText, militaryTime } = useSelector((state) => ({
        grid_count: state["features/base/settings"].grid_count,
        policy: state["features/base/settings"].policy,
        enableIconText: state["features/base/settings"].enableIconText,
        militaryTime: state["features/base/settings"].militaryTime,
    }), shallowEqual);
    // 현재 선택한 메뉴 타입을 useState를 이용하여 state로 관리
    const [type, setType] = useState('tab');
    // 라벨 상태를 useState를 이용하여 state로 관리
    const [labelStatus, setLabelStatus] = useState(true);
    // enableIconText 값이 바뀔 때마다 labelStatus state를 업데이트
    useEffect(() => {
        setLabelStatus(enableIconText);
    }, [enableIconText]);
    // 메뉴 타입 선택 리스트 컴포넌트를 반환하는 함수 
    const renderTap = useMemo(() => (SETTING_LIST.map((item) => {
        const name = (item === DEVICE_SET && type === 'tab') ? DEVICE_SET : item;
        if (!APP.config.option[name])
            return null;
        return (React.createElement(ClickButton, { key: item, className: (name === type || (name === DEVICE_SET && type === 'tab')) && !isMobile ? "isFocus" : "", isText: true, label: `dialog.${item}`, onClick: () => setType(item) }));
    })), [isMobile, type]);
    // 메뉴 이름 업데이트 함수
    const updateEnableClick = (data) => {
        dispatch(updateSettings(data));
    };
    const updateGridClick = (sync, count) => {
        dispatch(updateGridOption(sync, count, false));
    };
    // 방 환경 설정 함수 
    const handlerUpdatePolicy = option => {
        dispatch(updateRoomPolicy(option, false));
        dispatch(hideDialog());
    };
    // 버튼 클릭 시 실행되는 함수 
    const renderSubmitBtn = (handlerClick) => {
        return (React.createElement(ClickButton, { isText: true, label: 'dialog.add', onClick: handlerClick }));
    };
    // 메뉴 타입에 따라 렌더링되는 컴포넌트를 반환하는 함수
    const renderContent = () => {
        switch (type) {
            case 'tab':
                if (isMobile) {
                    return (React.createElement(DivElement, { className: 'mobile_tab' }, renderTap));
                }
            case DEVICE_SET:
                return (React.createElement(DevicePermissionContainer, { initial_camera: policy.initial_camera, initial_mic: policy.initial_mic, handlerUpdatePolicy: handlerUpdatePolicy }));
            case HANDLER_SET:
                return React.createElement(RaiseHandContainer, { handlerUpdatePolicy: handlerUpdatePolicy, renderSubmitBtn: renderSubmitBtn });
            case NOTIFICATION_SET:
                return React.createElement(SettingNotificationContainer, { renderSubmitBtn: renderSubmitBtn });
            case COUNT_SET:
                return (React.createElement(LayoutCountContainer, { grid_sync: policy.grid_count_option, show_grid_count: grid_count, renderSubmitBtn: renderSubmitBtn, updateGridClick: updateGridClick }));
            case CHAT_FILE_LOCK:
                if (!APP.config.option.chatFileLock)
                    return null;
                return (React.createElement(ChatLockFile, { isChatLock: policy.file_option === "allow" ? false : true, handlerUpdatePolicy: handlerUpdatePolicy }));
            case LABEL_SET:
                return (React.createElement(React.Fragment, null,
                    React.createElement(OnOffSwitch, { className: "label_option", guid: t("dialog.labelGuid"), label: enableIconText ? t("dialog.show") : t("dialog.hide"), isShow: enableIconText, handlerClick: () => updateEnableClick({ enableIconText: !enableIconText }) }),
                    React.createElement(RadioButton, { className: "military_time", guid: 'dialog.militaryTimeGuid', selectedOption: militaryTime, setSelectedOption: (value) => {
                            updateEnableClick({ militaryTime: value === "false" ? false : true });
                        }, list: [
                            { key: "trueMilitaryTime", id: true, label: t(`dialog.trueMilitaryTime`) },
                            { key: "falseMilitaryTime", id: false, label: t(`dialog.falseMilitaryTime`) },
                        ] })));
            case SCREEN_LAYOUT_SET:
                return (React.createElement(ScreenSetting, { screen_option: policy.screen_option, renderSubmitBtn: renderSubmitBtn, handlerUpdatePolicy: handlerUpdatePolicy }));
            default:
                return type;
        }
    };
    const goToList = () => setType('tab');
    return (React.createElement(Dialog, { className: "setting", titleKey: "dialog.setting", width: 700 },
        React.createElement(SettingStyled, { isMobile: isMobile, ref: layoutRef }, isMobile ?
            React.createElement(React.Fragment, null,
                type !== 'tab' && React.createElement(ClickButton, { className: "title", icon: 'back_arrow', color: '#ADADAD', size: 16, label: 'common.previous', onClick: goToList }),
                React.createElement(DivElement, { className: type === 'tab' ? '' : "content", style: { margin: `${type === 'tab' ? '0 auto' : '0'}` } },
                    (type == DEVICE_SET || type === HANDLER_SET) && React.createElement(TextElement, { text: "notify.equalSetGuid" }),
                    renderContent()))
            :
                React.createElement(React.Fragment, null,
                    React.createElement(DivElement, { className: 'tab' }, renderTap),
                    React.createElement(DivElement, { className: "content" },
                        (type === 'tab' || type == DEVICE_SET || type === HANDLER_SET) && React.createElement(TextElement, { text: "notify.equalSetGuid" }),
                        renderContent())))));
}
export default React.memo(SettingContainerDialog);
