import React from 'react';
import { useTranslation } from 'react-i18next';
const TextElement = React.memo(({ className, text, textProps, children, style }) => {
    // const { t } = useTranslation();
    // return (
    //     <p className={className} style={style}>
    //         {textProps ? t(text, textProps) : t(text)}
    //         {children}
    //     </p>
    // );
    const { t } = useTranslation();
    let translatedText;
    try {
        if (text === 'test') {
            translatedText = text;
        }
        else {
            translatedText = textProps ? t(text, textProps) : t(text);
        }
    }
    catch (error) {
        // An error occurred during translation, use the original text
        console.error('Translation error:', error);
        translatedText = text;
    }
    return (React.createElement("p", { className: className, style: style },
        translatedText,
        children));
});
export default TextElement;
