import { getMemberIdsList, getPinnedMember, getPinnedMemberCount, getPinnedMembersBySeminar, getScreenUserID, getVoiceMember, MEMBER_STATUS } from "../base/members";
import { toState } from "../base/redux";
import { getPropertyValue } from "../base/settings";
import { isRoomJoined } from "../room";
import { getScreenLayoutType, getScreenShareTrackId } from "../screen-share";
import { SHARE_TYPE } from "../screen-share/constants";
import { LAYOUT_TYPE, SHARE_MODE_LIST, SHARE_PERMISSION_MODE, videoLayoutMode } from "./constants";
/**
 * 듀얼 모니터 여부
 * @param {Function} stateful
 * @returns
 */
export function getDualMonitor(stateful) {
    const state = toState(stateful);
    return state['features/video-layout'].isDualMonitor;
}
/**
 * 알림 문구 표시
 */
export function getCurrentDualWindow(stateful) {
    const state = toState(stateful);
    return state['features/video-layout'].isDualMonitor && state['features/base/settings'].dualSideOpen;
}
/**
 * 현재 모드 가져오기
 * @param {IStore['getState']} statful
 * @param {CheckModeData} data
 * @returns
 */
export function getCurrentMode(statful, data) {
    const state = toState(statful);
    const mode = data?.mode || state['features/video-layout'].mode;
    const view_mode = data?.viewVisible || state['features/video-layout'].viewVisible;
    if (!view_mode)
        return mode;
    let current = mode;
    const viewMode = Object.entries(view_mode).find(([name, value]) => {
        if (value)
            return name;
    });
    if (viewMode && viewMode[0])
        current = viewMode[0];
    return current;
}
/**
 * 현재 레이아웃 타입
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLayoutType(stateful) {
    const state = toState(stateful);
    return state['features/video-layout'].layoutType;
}
/**
 * 현재 모드에 따른 사용자 정보 가져오기
 * @param {IStore['getState']} stateful
 * @param {Object} layoutStyle
 */
export function getMembersByMode(stateful, layoutStyle) {
    if (!isRoomJoined(stateful))
        return null;
    const mode = getCurrentMode(stateful);
    const layout_type = getLayoutType(stateful);
    const { page } = getPage(stateful);
    const isDualMonitor = getDualMonitor(stateful);
    const screenLayoutType = getScreenLayoutType(stateful);
    const grid_count = getPropertyValue(stateful, "grid_count");
    let data = {
        status: [MEMBER_STATUS.OCCUPIDE],
        filterGroup: true
    };
    let pinMember = null;
    let seminarMembers = null;
    let members = [];
    let voiceMember = null;
    let screenMembers = null;
    let voice = '';
    let screen = [];
    let max = 0;
    let count = grid_count;
    let seminar = null;
    let showOption = {
        showRemote: true, showPin: false, showVoice: false, showSeminar: false,
        showScreen: true
    };
    if (isDualMonitor) {
        if (SHARE_MODE_LIST.includes(mode)) {
        }
        else {
            switch (mode) {
                case videoLayoutMode.grid:
                case videoLayoutMode.seminar:
                    showOption.showSeminar = true;
                    seminar = getPinnedMembersBySeminar(stateful, true);
                    break;
                case videoLayoutMode.pin:
                    showOption.showPin = true;
                    showOption.showVoice = false;
                    data = { ...data, excludePin: true };
                    break;
                case videoLayoutMode.voice:
                    showOption.showPin = true;
                    showOption.showVoice = true;
                    voice = getVoiceMember(stateful);
                    data = { ...data, excludePin: true, excludeVoice: true };
                    break;
            }
        }
    }
    else {
        if (layout_type === LAYOUT_TYPE.desktop) {
            let showMaxCount = count;
            if (SHARE_MODE_LIST.includes(mode) || videoLayoutMode.pin === mode || videoLayoutMode.voice === mode) {
                showMaxCount = Math.round(layoutStyle.width / 172);
            }
            else if (videoLayoutMode.screen === mode) {
                showMaxCount = Math.floor(layoutStyle.height / 98);
            }
            switch (mode) {
                case videoLayoutMode.pin:
                    showOption.showPin = true;
                    data = { ...data, excludePin: true };
                    count = showMaxCount;
                    break;
                case videoLayoutMode.voice:
                    showOption.showPin = true;
                    showOption.showVoice = true;
                    voice = getVoiceMember(stateful);
                    data = { ...data, excludePin: true, excludeVoice: true };
                    count = showMaxCount;
                    break;
                case videoLayoutMode.seminar:
                    showOption.showRemote = false;
                    showOption.showSeminar = true;
                    seminar = getPinnedMembersBySeminar(stateful, true);
                    break;
                case videoLayoutMode.screen:
                    if (screenLayoutType === SHARE_TYPE) {
                        showOption.showRemote = false;
                        showOption.showScreen = true;
                        const sharingID = getScreenUserID(stateful);
                        screen = sharingID ? [sharingID] : [];
                    }
                    else {
                        const pinCount = getPinnedMemberCount(stateful);
                        if (pinCount < showMaxCount) {
                            showOption.showPin = true;
                            data = { ...data, excludePin: true };
                            count = showMaxCount - pinCount;
                        }
                        else {
                            count = showMaxCount;
                        }
                    }
                    break;
                case videoLayoutMode.document:
                case videoLayoutMode.white:
                case videoLayoutMode.vod:
                case videoLayoutMode.note:
                    const expand = getExpand(stateful);
                    if (expand) {
                        const pinCount = getPinnedMemberCount(stateful);
                        if (pinCount < showMaxCount) {
                            showOption.showPin = true;
                            data = { ...data, excludePin: true };
                            count = showMaxCount - pinCount;
                        }
                        else {
                            count = showMaxCount;
                        }
                    }
                    else {
                        showOption.showRemote = false;
                        showOption.showSeminar = true;
                        seminar = getPinnedMembersBySeminar(stateful, true);
                    }
                    break;
            }
        }
        else if (layout_type === LAYOUT_TYPE.tablet || layout_type === LAYOUT_TYPE.mobile) {
            if (SHARE_PERMISSION_MODE.includes(mode)) {
                count = 0;
            }
            else {
                if (mode === videoLayoutMode.seminar) {
                    showOption.showRemote = false;
                    showOption.showSeminar = true;
                    seminar = getPinnedMembersBySeminar(stateful, true);
                    count = 1;
                }
                else if (mode === videoLayoutMode.pin || mode === videoLayoutMode.voice) {
                    let voicePage = 0;
                    if (mode === videoLayoutMode.voice) {
                        voice = getVoiceMember(stateful);
                        if (voice !== '')
                            voicePage = 1;
                    }
                    const pinCount = getPinnedMemberCount(stateful, voice);
                    const pinPage = pinCount > 0 ? Math.ceil(pinCount / 4) : 0;
                    if (mode === videoLayoutMode.voice) {
                        if (page <= voicePage) {
                            count = 1;
                        }
                        else if (page <= voicePage + pinPage && page > voicePage) {
                            data = { ...data, excludeVoice: true };
                            count = Math.min(pinCount, 4);
                            max = voicePage;
                        }
                        else {
                            data = { ...data, excludePin: true, excludeVoice: true };
                            max = pinPage + voicePage;
                            count = 4;
                        }
                    }
                    else {
                        if (page <= pinPage) {
                            count = Math.min(pinCount, 4);
                        }
                        else {
                            data = { ...data, excludePin: true };
                            max = pinPage;
                            count = 4;
                        }
                    }
                }
                else {
                    count = 4;
                }
            }
        }
    }
    let maxCount = 0;
    let newMembers = new Set();
    if (showOption.showRemote) {
        members = getMemberIdsList(stateful, data, false);
        maxCount = members.length;
        const start = count * (page - max - 1);
        const end = count * (page - max);
        members = members.slice(start, end);
        if (!members)
            newMembers = new Set(members);
    }
    if (showOption.showVoice)
        voiceMember = voice === '' ? null : new Set([voice]);
    if (showOption.showPin) {
        pinMember = getPinnedMember(stateful, voice);
    }
    if (showOption.showSeminar)
        seminarMembers = !seminar ? null : new Set(seminar);
    if (showOption.showScreen)
        screenMembers = new Set(screen);
    return {
        page,
        maxPage: Math.ceil(maxCount / count) < 1 ? 1 + max : Math.ceil(maxCount / count) + max || 1,
        remote: members,
        pin: pinMember,
        voice: voiceMember,
        seminar: seminarMembers,
        screen: screenMembers
    };
}
/**
 * 방 설정 권한 확인 (레이아웃에서만 사용)
 * @param {IStore['getState']} stateful
 * @param {String} type
 * @returns Boolean
 */
export function checkLayoutPermission(stateful, type) {
    const state = toState(stateful);
    const permissions = state['features/room'].permissions;
    if (permissions.indexOf(type) !== -1)
        return true;
    return false;
}
// /**
//  * 그리드 모드 옵션 가져오기 
//  * @param {IStore['getState']} stateful 
//  * @returns 
//  */
// export function getGridCount(stateful: IStore['getState']) {
//     const state = toState(stateful);
//     return state['features/video-layout'].grid_count;
// }
/**
 * 공유 모드에서 화면이 펼쳐져있냐 안펼쳐져있냐
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getExpand(stateful) {
    const state = toState(stateful);
    return state['features/video-layout'].expand;
}
/**
 * 현재 레이아웃 스타일 크기 가져오기
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLayoutWidthAndHeight(stateful) {
    const state = toState(stateful);
    return {
        width: state['features/video-layout'].layoutWidth,
        height: state['features/video-layout'].layoutHeight
    };
}
/**
 * 모드에 표시 되는 숫자
 * @param {Function} stateful
 * @returns
 */
export function getPage(stateful) {
    const state = toState(stateful);
    const { page, maxPage } = state['features/video-layout'];
    return { page, maxPage };
}
/**
 * 현재 화면에 맞는 타입 사용자 가져오기
 * @param {IStore['getState']} stateful
 * @param {String} type
 * @returns
 */
export function getLayoutMemberByType(stateful, type) {
    const state = toState(stateful);
    return state['features/video-layout'][type];
}
/**
 * 현재 화면에 remote 사용자가 존ㅈㅐ 하는지에 대한 여부
 * @param {IStore['getState']} stateful
 * @param {String} type
 * @returns
 */
export function isShowByType(stateful, type) {
    const state = toState(stateful);
    const members = state['features/video-layout'][type];
    return !members || members.length <= 0 ? false : true;
}
/**
 * 사용자 크기 가져오기
 * @param {Function} stateful
 * @param {Number} memberCount
 * @param {Object} style
 * @param {Boolean} half
 * @returns
 */
export function getFilmStyle(stateful, memberCount, style, half = false) {
    // const state = toState(stateful);
    const showCount = memberCount; // Math.min(state['features/video-layout'].showCount, memberCount);
    let { width, height } = style || getLayoutWidthAndHeight(stateful);
    if (!showCount || !width || !height)
        return { width: 0, height: 0 };
    height = half ? height / 2 : height;
    const columns = getGirdColumns(showCount);
    const rows = getGirdRows(showCount);
    let newWidth = width / columns;
    let newHeight = height / rows;
    let filmWidth = newWidth;
    let filmWHeight = (9 * filmWidth) / 16;
    if (Math.floor(newHeight / filmWHeight) < rows && newHeight < filmWHeight) {
        filmWHeight = newHeight;
        filmWidth = (16 * filmWHeight) / 9;
    }
    return { width: filmWidth, height: filmWHeight };
}
/**
 * 그리드 행 가져오기
 * @param {Number} count
 * @returns
 */
function getGirdColumns(count) {
    const rows = Math.round(Math.sqrt(count));
    const alpha = count - rows * rows;
    const plus = alpha > 0 ? 1 : 0;
    const columns = rows + plus;
    return columns;
}
/**
 * 그리드 열 가져오기
 * @param {Number} count
 * @returns
 */
function getGirdRows(count) {
    const rows = Math.round(Math.sqrt(count));
    return rows;
}
const VIDEO_QUALITY_LEVELS = {
    ULTRA: 2160,
    HIGH: 720,
    STANDARD: 360,
    LOW: 180,
    NONE: 0
};
/**
 * 영상 화질 판단
 */
export function allocateUsersByVideoQuality(state, data) {
    const isDualMonitor = getDualMonitor(state);
    const mode = getCurrentMode(state);
    const { remote, pin, voice, seminar, screen } = data;
    let constraintsList = {
        [VIDEO_QUALITY_LEVELS.LOW]: new Set(),
        [VIDEO_QUALITY_LEVELS.STANDARD]: new Set(),
        [VIDEO_QUALITY_LEVELS.HIGH]: new Set()
    };
    // lastN 제한없음. 기본값
    let lastN = -1;
    // 기본 엔드포인트 제약. 수신 안함
    const defaultConstraints = {
        "maxHeight": 0,
    };
    // 스테이지 또는 선택
    const selectedOrOnStage = new Map();
    // 엔드포인트 제약
    const constraints = {};
    switch (mode) {
        case videoLayoutMode.note:
        case videoLayoutMode.white:
        case videoLayoutMode.document:
        case videoLayoutMode.vod:
            // 문서 모드
            {
                const withoutScreen = [...remote, ...pin, ...voice, ...seminar];
                for (let i = 0; i < withoutScreen.length; i++) {
                    constraints[`${withoutScreen[i]}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.LOW
                    };
                }
            }
            break;
        case videoLayoutMode.grid:
            // 격자 모드
            {
                let quality;
                if (remote.length <= 10)
                    quality = VIDEO_QUALITY_LEVELS.HIGH;
                else if (remote.length <= 20)
                    quality = VIDEO_QUALITY_LEVELS.STANDARD;
                else
                    quality = VIDEO_QUALITY_LEVELS.LOW;
                for (let i = 0; i < remote.length; i++) {
                    constraints[`${remote[i]}-v0`] = {
                        "maxHeight": quality
                    };
                }
            }
            break;
        case videoLayoutMode.pin:
            // 발표 모드
            // 핀 => high / onStage
            {
                for (let i = 0; i < remote.length; i++) {
                    constraints[`${remote[i]}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.LOW
                    };
                }
                for (let i = 0; i < pin.length; i++) {
                    selectedOrOnStage.set(`${pin[i]}-v0`, "onStage");
                    constraints[`${pin[i]}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.HIGH
                    };
                }
            }
            break;
        case videoLayoutMode.voice:
            // 발언 모드
            // 발언자 => high /selected
            // 핀 => high / onStage
            {
                for (let i = 0; i < remote.length; i++) {
                    constraints[`${remote[i]}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.LOW
                    };
                }
                for (let i = 0; i < voice.length; i++) {
                    selectedOrOnStage.set(`${voice[i]}-v0`, "selected");
                    constraints[`${voice[i]}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.HIGH
                    };
                }
                for (let i = 0; i < pin.length; i++) {
                    selectedOrOnStage.set(`${pin[i]}-v0`, "onStage");
                    constraints[`${pin[i]}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.HIGH
                    };
                }
            }
            break;
        case videoLayoutMode.screen:
            // 화면공유 모드
            // 화면공유자 => high / selected
            // 공유된 화면 => high / onStage            
            {
                const screen_track_id = getScreenShareTrackId(state);
                const remoteAndPin = [...remote, ...pin];
                for (let i = 0; i < remoteAndPin.length; i++) {
                    constraints[`${remoteAndPin[i]}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.LOW
                    };
                }
                for (let i = 0; i < screen.length; i++) {
                    selectedOrOnStage.set(`${screen[i]}-v0`, "selected");
                    constraints[`${screen[i]}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.HIGH
                    };
                }
                if (screen_track_id) {
                    selectedOrOnStage.set(`${screen_track_id}-v0`, "onStage");
                    constraints[`${screen_track_id}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.HIGH
                    };
                }
            }
            break;
        case videoLayoutMode.seminar:
            // 세미나 모드
            // 세미나 => high / onStage
            // 핀 => high / onStage
            {
                for (let i = 0; i < seminar.length; i++) {
                    selectedOrOnStage.set(`${seminar[i]}-v0`, "onStage");
                    constraints[`${seminar[i]}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.HIGH
                    };
                }
                for (let i = 0; i < pin.length; i++) {
                    selectedOrOnStage.set(`${pin[i]}-v0`, "onStage");
                    constraints[`${pin[i]}-v0`] = {
                        "maxHeight": VIDEO_QUALITY_LEVELS.HIGH
                    };
                }
            }
            break;
    }
    const selectedSources = [];
    const onStageSources = [];
    selectedOrOnStage.forEach((value, key) => {
        if (value === "selected") {
            selectedSources.push(key);
        }
        else if (value === "onStage") {
            onStageSources.push(key);
        }
    });
    return {
        "colibriClass": "ReceiverVideoConstraints",
        "lastN": lastN,
        "selectedSources": selectedSources,
        "onStageSources": onStageSources,
        "defaultConstraints": defaultConstraints,
        "constraints": constraints,
    };
}
