import { createRoom, tempEnterRoom } from "../../room";
import { APP_WILL_MOUNT } from "../app";
import { MiddlewareRegistry } from "../redux";
import { setDisplayName, updateSettings } from "../settings";
// @ts-ignore
import { storage } from "../util";
import { CLASS_MATE_LOGIN } from "./actionTypes";
import { getSearchData, loginByPartnerToken, loginByToken } from "./functions";
MiddlewareRegistry.register(store => next => async (action) => {
    const { dispatch } = store;
    switch (action.type) {
        case APP_WILL_MOUNT:
            const storageKey = getSearchData('storage_key');
            const storageToken = storageKey ? storage.get(storageKey)?.token : storage.get('token');
            if (storageToken) {
                await loginByToken(storageToken);
            }
            const data = storageKey ? storage.get(storageKey) : null;
            if (data && data.ui_option) {
                const uiOption = data.ui_option;
                store.dispatch(updateSettings({
                    displayName: uiOption.defaultNickname || 'test',
                    enableLobby: uiOption.enableLobbyPage || false,
                    enableNickname: uiOption.enableNicknamePage || false
                }));
            }
            break;
        case CLASS_MATE_LOGIN:
            storage.remove('token');
            const { token, user_id, nickname, roomCode, roomName, isCoach } = action.data;
            const response = await loginByPartnerToken({ token, user_id });
            if (response.complete) {
                dispatch(setDisplayName(nickname));
                if (roomCode && roomCode !== '') {
                    tempEnterRoom({ code: roomCode })
                        .then(response => {
                        if (!response.complete)
                            APP.UI.alertMessage(response.message);
                    });
                }
                else if (roomName && isCoach) {
                    createRoom(roomName)
                        .then(response => {
                        if (!response.complete)
                            APP.UI.alertMessage(response.message);
                    });
                }
            }
            break;
    }
    return next(action);
});
