import React, { forwardRef } from "react";
import Icon from "./Icon";
import { useTranslation } from "react-i18next";
const ClickButton = forwardRef(({ className, isText, styles, label, color = "#fff", icon, size = 20, onClick }, ref) => {
    const { t } = useTranslation();
    return (React.createElement("div", { ref: ref, style: styles, className: `click_button ${className} ${isText ? "text-button" : ""} ${color ? "no-color" : ""}`, onClick: onClick },
        icon && React.createElement(Icon, { icon: icon, size: size, color: color }),
        label && (React.createElement("span", { className: "overText", style: { ...(styles?.text || {}) } }, typeof label === 'string' ? t(label) : label))));
});
export default ClickButton;
