import { MEDIA_TYPE, VIDEO_MUTISM_AUTHORITY, setAudioMuted, setVideoMuted } from "../base/media";
import { ROLE, checkRoleLevel, getLocalMember, getLocalMemberRole } from "../base/members";
import { MiddlewareRegistry } from "../base/redux";
import { UPDATE_ROOM_POLICY, updateSettings } from "../base/settings";
import { getTrackState, isLocalTrackMuted } from "../base/tracks";

MiddlewareRegistry.register(store => next => async action => {
    switch (action.type) {
        case UPDATE_ROOM_POLICY:
            const policy = action.policy;
            const local = getLocalMember(store.getState);

            
            if (!checkRoleLevel(local?.role, ROLE.MANAGER) || local?.pinned) {
                const isVideoMuted = isLocalTrackMuted(getTrackState(store.getState), MEDIA_TYPE.VIDEO);
                const isAudioMuted = isLocalTrackMuted(getTrackState(store.getState), MEDIA_TYPE.AUDIO);

                if (!isVideoMuted) store.dispatch(setVideoMuted(policy.initial_camera === 'off' ? true : false, MEDIA_TYPE.VIDEO, VIDEO_MUTISM_AUTHORITY.USER, false));
                if (!isAudioMuted) store.dispatch(setAudioMuted(policy.initial_mic === 'off' ? true : false, MEDIA_TYPE.AUDIO, false));
            }

            
            if (policy.initial_camera === 'following' || policy.initial_mic === 'following') {
                const iCamera = policy.initial_camera === 'off' ? 'off' : 'on';
                const iMic = policy.initial_mic === 'off' ? 'off' : 'on';                
                
                return next({
                    ...action,
                    policy: {
                        ...policy,
                        initial_camera: iCamera,
                        initial_mic: iMic
                    }
                });
            }            
    }

    return next(action);
});