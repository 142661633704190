// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./font/pretendard.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!./font/SUITE.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{padding:0;margin:0 auto}html,body{height:100%;width:100%;font-family:Pretendard,sans-serif;overscroll-behavior:contain;font-size:13px}.parents{width:100%;height:100%}@media only screen and (min-width: 1200px){.parentsv{min-width:450px;min-height:450px}}.flipVideoX{transform:scale(-1, 1);-moz-transform:scale(-1, 1);-webkit-transform:scale(-1, 1);-o-transform:scale(-1, 1)}`, "",{"version":3,"sources":["webpack://./src/css/reset.scss"],"names":[],"mappings":"AAGA,EAAA,SAAA,CAAA,aAAA,CAEA,UACI,WAAA,CACA,UAAA,CACA,iCAAA,CACA,2BAAA,CACA,cAAA,CAGJ,SACI,UAAA,CACA,WAAA,CAGJ,2CACI,UACI,eAAA,CACA,gBAAA,CAAA,CAIR,YACI,sBAAA,CACA,2BAAA,CACA,8BAAA,CACA,yBAAA","sourcesContent":["@import \"./font/pretendard.css\";\n@import \"./font/SUITE.css\";\n\n* {padding: 0; margin: 0 auto;}\n\nhtml, body {\n    height: 100%; \n    width: 100%; \n    font-family: Pretendard, sans-serif;\n    overscroll-behavior: contain; \n    font-size: 13px;      \n}\n\n.parents {\n    width: 100%;\n    height: 100%;\n}\n\n@media only screen and (min-width: 1200px) {\n    .parentsv {\n        min-width: 450px;\n        min-height: 450px;\n    }\n}\n\n.flipVideoX {\n    transform: scale(-1, 1);\n    -moz-transform: scale(-1, 1);\n    -webkit-transform: scale(-1, 1);\n    -o-transform: scale(-1, 1);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
