import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ROLE, checkRoleLevel, getLocalMemberRole } from "../base/members";
import { ClickButton, DivElement, TextElement } from "../base/ui";
function DevicePermissionContainer({ initial_camera, initial_mic, handlerUpdatePolicy }) {
    const local_role = useSelector((state) => getLocalMemberRole(state));
    const [camera, setCamera] = useState(true);
    const [mic, setMic] = useState(true);
    const [checkRole, setCheckRole] = useState(false);
    useEffect(() => {
        setCheckRole(checkRoleLevel(local_role, ROLE.MANAGER));
    }, [local_role]);
    useEffect(() => {
        setMic(initial_mic === "off" ? false : true);
    }, [initial_mic]);
    useEffect(() => {
        setCamera(initial_camera === "off" ? false : true);
    }, [initial_camera]);
    /**
     * (TRACK) 전체 오디오 ON / OFF
     * @param {Boolean} muted
     * @returns
     */
    const audioMuted = async (isMuted) => {
        const response = await APP.management.setAllAudio(isMuted);
        if (response.status === 200) {
            handlerUpdatePolicy({ initial_camera, initial_mic: isMuted ? 'on' : 'off' });
        }
    };
    /**
     * (TRACK) 전체 비디오 ON / OFF
     * @param {Boolean} muted
     * @returns
     */
    const videoMuted = async (isMuted) => {
        const response = await APP.management.setAllVideo(isMuted);
        if (response.status === 200) {
            handlerUpdatePolicy({ initial_camera: isMuted ? 'on' : 'off', initial_mic });
        }
    };
    const mediaButtons = useMemo(() => [{
            key: "allAudioOn",
            label: "devicePermissionSetting.allAudioOn",
            icon: "allAudioOn",
            focusClass: mic ? "isFocus" : "",
            onClick: () => audioMuted(true)
        }, {
            key: "allAudioOff",
            label: "devicePermissionSetting.allAudioOff",
            icon: "allAudioOff",
            focusClass: !mic ? "isFocus" : "",
            onClick: () => audioMuted(false)
        }, {
            key: "allVideoOn",
            label: "devicePermissionSetting.allVideoOn",
            icon: "allVideoOn",
            focusClass: camera ? "isFocus" : "",
            onClick: () => videoMuted(true)
        }, {
            key: "allVideoOff",
            label: "devicePermissionSetting.allVideoOff",
            icon: "allVideoOff",
            focusClass: !camera ? "isFocus" : "",
            onClick: () => videoMuted(false)
        }], [mic, camera]);
    const renderButtons = useMemo(() => {
        return mediaButtons.map(item => (React.createElement(ClickButton, { key: item.key, className: `media-button ${!checkRole && 'no-hover'} ${item.focusClass}`, label: item.label, icon: item.icon, onClick: checkRole ? item.onClick : null, size: 30, numberOfLines: 2 })));
    }, [checkRole, mediaButtons]);
    return (React.createElement(React.Fragment, null,
        checkRole && React.createElement(TextElement, { className: 'guid', text: 'devicePermissionSetting.guid' }),
        React.createElement(DivElement, { className: 'device-permission-btn' }, renderButtons)));
}
export default React.memo(DevicePermissionContainer);
